<template>
  <el-aside :class="{ collapse: isCollapse }">
    <el-menu mode="horizontal" :default-active="$route.name" :collapse="true">
      <div class="logos">
        <h2 class="title" @click="toHome">EG Group 管理系统</h2>
      </div>
      <SlideBarItem class="SlideBarItem" v-for="item in authMenus" :key="item.path" :item="item" />
      <div class="userInfo">
        <el-dropdown @visible-change="changeDropdown" @command="handleCommand">
          <span class="el-dropdown-link">{{ nickname
          }}<i style="padding-left: 10px" :class="[icondrop ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i></span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="个人中心">个人中心</el-dropdown-item> -->
            <el-dropdown-item command="退出登录">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
import SlideBarItem from "./Item.vue";
import { logout } from "@/api/login.js";
import config from "@/utils/config.js";
import whiteList from "./white-list.js"
export default {
  name: "",
  components: { SlideBarItem },
  data() {
    return {
      ...whiteList,
      icondrop: false,
      authMenus: [],
      ENV_TEST: config.ENV_TEST,
    };
  },
  computed: {
    isCollapse() {
      return false
    },
    routes() {
      return this.$store.getters.routes;
    },
    nickname() {
      return localStorage.getItem("username")
    }
  },
  mounted() {
    // var menu = this.$store.getters.routes;
    this.authMenus = this.$store.getters.routes
    console.log(this.$store.getters.routes)
    // var managerType = localStorage.getItem("managerType");
    // try {
    //   if (managerType == 0) {
    //     this.authMenus = this.$store.getters.routes.filter((item) => {
    //       if (this.whiteList.indexOf(item.name) == -1) {
    //         return item
    //       }
    //     })
    //   } else if (managerType == 1) {
    //     this.authMenus = this.$store.getters.routes.filter((item) => {
    //       return item.name != "authConfiguration" && this.whiteList.indexOf(item.name) == -1
    //     })
    //   }
    // } catch (e) {
    //   console.log("发生异常", e);
    // }
  },
  methods: {
    changeDropdown(bool) {
      this.icondrop = bool;
    },
    toHome() {
      if (location.href.indexOf("home") == -1) {
        this.$router.replace("/home");
      }
    },
    handleCommand() {
      logout().then((res) => {
        if (res.code == 200) {
          localStorage.setItem("eg_group_token", '')
          localStorage.setItem("managerType", '')
          localStorage.setItem("managerRight", '')
          localStorage.setItem("username", '')
          this.$router.replace("/login");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-aside {
  width: 100% !important;
  // min-width: 1000px !important;
  height: 60px !important;
  background: linear-gradient(90deg, #1d42ab, #2173dc, #1e93ff);

  /deep/ .el-menu {
    width: 100% !important;
    background-color: transparent;
    padding: 0 26px;
    border: none;
    display: flex;
    overflow-x: auto;

    // .SlideBarItem{
    //   flex: 1;
    // }
    .userInfo {
      min-width: 100px;
      flex: 1;
      height: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      span {
        color: #fff;
      }
    }

    .logos {
      height: 60px;
      color: #fff;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      .title {
        min-width: 200px;
        line-height: 45px;
        font-size: 26px;
      }
    }

    .el-submenu {
      float: left;

      &.is-active {
        .el-submenu__title {
          background-color: transparent;
          color: #fff;

          i {
            color: #fff;
          }
        }
      }

      .el-submenu__title {
        color: #bdd2f1;

        &:hover {
          background-color: transparent;
          color: #fff;

          i {
            color: #fff;
          }
        }

        >i {
          color: #bdd2f1;
        }
      }
    }

    .el-menu-item {
      float: left;

      &.is-active,
      &:hover {
        background-color: transparent;
        color: #fff;

        .el-submenu__title,
        a,
        i {
          color: #fff;
          background-color: transparent;
        }
      }

      .el-submenu__title,
      a,
      i {
        color: #bdd2f1;

        &:hover {

          &,
          i {
            color: #fff;
          }
        }
      }
    }
  }

  // /deep/ .el-menu-item > a > span,
  // /deep/ .el-submenu__title > span {
  //   position: relative;
  //   right: 0;
  //   transition: right 1s;
  // }
  // &.collapse {
  //   width: 64px !important;
  //   /deep/ .el-menu-item > a > span,
  //   /deep/ .el-submenu__title > span {
  //     right: -100px;
  //   }
  // }
}</style>
