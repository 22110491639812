import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import CONFIG from './config'
const baseURL = CONFIG.ENV_TEST?  CONFIG.BASE_URL_OFF : CONFIG.BASE_URL;
const webURL = CONFIG.ENV_TEST? CONFIG.BASE_WEB_OFF : CONFIG.BASE_WEB

let defaultParams = {
  token: localStorage.getItem("eg_group_token") || '',
}

function checkToken () {
  if (localStorage.getItem("eg_group_token")) {
    defaultParams['token'] = localStorage.getItem("eg_group_token")
  }
  if (localStorage.getItem("username")) {
    defaultParams['ua'] = localStorage.getItem("username")
  }
}

const service = axios.create({
  baseURL: baseURL,
  timeout: 50000
})


service.interceptors.response.use(
  (res) => {
    if (res && res.data) {
      if(res.data.code == 206){
        MessageBox.confirm('您已经退出登录状态，您可以点击取消留在当前页面或者重新登录', {
          confirmButtonText: '重新登录',
          showCancelButton:false,
          type: 'warning',
        }).then(() => {
          localStorage.setItem("eg_group_token","")
          location.href = `${webURL}/#/login`
        }).catch(() => {})
      } else if(res.data.code != 200){
        Message.error(res.data.message)
      } 
    }
    return res;
  },
  (err) => {
    if (err && err.response) {
      if(err.response.data.code != 200){
        Message.error(err.response.data.message)

      }
    } else {
      err.message = "连接到服务器失败";
    }
    return Promise.resolve(err.response);
  }
)

service.get = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url,
      params: { ...param },
      headers:{
        "token" : defaultParams['token']
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
},
service.getBlob = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    return service({
      url,
      method: 'GET',
      params: { ...param },
      responseType: 'blob',
      headers:{
        "token" : defaultParams['token']
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    });
  });
},
service.post = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    
    service({
      method: "post",
      url,
      data: { ...param },
      headers:{
        "token" : defaultParams['token'],
        "content-type": 'application/json'
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      Message.error(err.message)
      reject(err);
    });
  });
};



export default service
