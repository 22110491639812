import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout/index.vue'
import circle from './modules/circle'
// import store from '@/store'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    meta: { notNeedAuth: true },
    component: () => import('@/views/login/login.vue'),
  },
  {
    path: '/404',
    name: 'NotFund',
    hidden: true,
    meta: { hidden: true },
    component: () => import('@/views/Error/404.vue'),
  },
]
export const permissionRoutes = [
  {
    path: '/',
    name: 'Root',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: 'home',
        hidden: true,
        meta: { title: '首页', icon: 'el-icon-s-home', hidden: true, fixed: true ,requireAuth: true },
        component: () => import('@/views/home/home.vue'),
      },
    ],
  },
  circle,
  {
    path: '*',
    name: 'Error',
    hidden: true,
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export function resetRouter() {
  const newRouter = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  })
  router.matcher = newRouter.matcher
}

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    var token = localStorage.getItem("eg_group_token");
    if (token) {
      next();
    } else { 
      next({
          path: '/login',
      })
    }
  } else {
    next();
  }
})

export default router
