import store from '@/store'

const sessionStore = localStorage.getItem('store')

if (sessionStore && sessionStore !== 'undefined') {
  store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStore)))
  store.dispatch('permission/handleRoutes')
}

window.addEventListener('beforeunload', () => {
  localStorage.setItem('store', JSON.stringify(store.state))
})

export default store
