import request from '@/utils/request'


export function Login (params) {
  return request.post('/api/login', params)
}

export function logout () {
  return request.post('/api/loginOut')
}
