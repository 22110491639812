<template>
  <div>
    <el-table
      border
      class="base-table"
      :data="data"
      style="width: 100%"
      header-cell-class-name="table_header"
      @selection-change="handleTableCurrentChange"
      @row-click="handleTableRowClick"
      :cell-style="cellStyle"
      :highlight-current-row="highlightCurrent"
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, .6)"
    >
       <template v-for="(item, index) in columns">
         <el-table-column
           v-if="item.selection"
           type="selection"
           :key="`selection_${index}`"
         ></el-table-column>
         <el-table-column
           v-else-if="item.index"
           type="index"
           label="序号"
           :index="item"
           :key="`index_${index}`"
         ></el-table-column>
         <el-table-column
           v-else-if="item.multi"
           align="center"
           :label="item.label"
           :key="`multi_${index}`"
         >
           <el-table-column
             v-for="(child, childIndex) in item.children"
             :key="`child_${index}_${childIndex}`"
             v-bind="child"
           >
           </el-table-column>
         </el-table-column>
         <slot
           v-else-if="item.slot"
           show-overflow-tooltip
           :name="item.slot"
           :fixed="item.fixed"
           :height="item.height"
         ></slot>
         <el-table-column
           v-else
           v-bind="item"
           :key="`normal_${index}`"
         >

         </el-table-column>
       </template>

    </el-table>
    <div class="block elpag">
      <el-pagination
        v-if="isPaginationShow && total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.per_page"
        layout="total ,prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'baseTable',
  props:{
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({
        page: 1,
        size: 10,
        total: 0,
      }),
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    isPaginationShow: {
      type: Boolean,
      default: true,
    },
    wrapperHeight: {
      type: [Number, String],
      default: '100%', 
    },
    height: {
      type: [Number, String],
      default: 'calc(100% - 48px)',
    },
    maxHeight: {
      type: [Number, String],
      default: 'auto',
    },
    tableSize: {
      type: String,
      default: 'small',
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    otherConfig: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    highlightCurrent: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{

    }
  },
  methods:{
    cellStyle: function (e) {  
      let obj = {};
      this.$emit("cellStyle", e, (color = {}) => {     
        obj = color;
      });
      return obj;
    },
    // 切换页码
    handleCurrentChange() {
      this.$emit('getData');
    },
    // 切换每页条数
    handleSizeChange(value) {
      this.pagination.per_page = value;
      this.$emit('getData');
    },
    // 切换选择
    handleSelectionChange(val) {
      this.$emit('changeSelection', val);
    },
    // 单选
    handleTableCurrentChange(currentRow) {
      this.$emit('changeCurrent', currentRow);
    },
    // 点击行
    handleTableRowClick(currentRow) {
      this.$emit('rowClick', currentRow);
    },
  },
  watch: {
    data() {
      // 重新请求数据时 table滚动到顶部
    },
    pagination(){
    //   console.log("newData",newData)
    //   console.log("oldData",oldData)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.base-table{
    width: 100%;
    overflow: auto;
    font-size: 12px;
    ::-webkit-scrollbar-thumb {
        border-radius: 45px;
        background-color: #a3a3a3;
    }
}
/deep/ .table_header{
    background-color: #f8f8f9 !important;
    color: #515a6e !important;
}
.elpag{
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>