import Layout from '@/Layout/index.vue'
export default {
  path: '/circle',
  name: 'circle',
  redirect: '/circle/circleManager',
  auth_key: "circleManager",
  meta: { title: '车圈管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'circleManager',
      name: 'circleManager',
      auth_key: "h5UserList",
      meta: { title: '车圈', hidden: false,requireAuth: true },
      component: () => import('@/views/car/circle.vue')
    },
    {
      path: 'circleType',
      name: 'circleType',
      auth_key: "h5UserList",
      meta: { title: '车圈类型', hidden: false,requireAuth: true },
      component: () => import('@/views/car/circleType.vue')
    }
  ]
}